import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const InlineNotification = makeShortcode("InlineNotification");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Foundation Setup`}</h2>
    <p>{`Legion design Libraries are separated into multiple projects. The first step, make sure you were invited to project file from the `}<a parentName="p" {...{
        "href": "https://forms.gle/vNvoJ5t6FDM7D1VD8"
      }}><strong parentName="a">{`Request Form`}</strong></a>{`, then check the available library for use in the table of list library or the project you assigned, and select the design library before you start designing.`}</p>
    <h2>{`Define colors from brand`}</h2>
    <p>{`This section will guide you to begin by creating your brand or product logo as the first step in establishing your identity, followed by defining primary, secondary, tertiary, and accent colors. This ensures a cohesive visual identity across all brand touchpoint.`}</p>
    <div {...{
      "className": "gatsby-resp-iframe-wrapper",
      "style": {
        "paddingBottom": "56.25%",
        "position": "relative",
        "height": "0",
        "overflow": "hidden"
      }
    }}>{` `}<iframe parentName="div" {...{
        "src": "https://www.youtube.com/embed/RCX2wddY7ec?si=8jnXAgRMOd1FKDUM",
        "title": "YouTube video player",
        "frameBorder": "0",
        "allow": "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
        "referrerPolicy": "strict-origin-when-cross-origin",
        "allowFullScreen": true,
        "style": {
          "position": "absolute",
          "top": "0",
          "left": "0",
          "width": "100%",
          "height": "100%"
        }
      }}></iframe>{` `}</div>
    <h3>{`1. Create your brand or product logo`}</h3>
    <p>{`Start by crafting a logo for your brand or product that effectively captures your identity. Ensure it resonates with your vision and values. Follow the provided guidelines to select the appropriate size, type, and color variants that harmonize with your brand’s identity.`}</p>
    <h3>{`2. Define primary and secondary colors`}</h3>
    <p>{`Understand the difference between primary and secondary colors within your color palette. Primary colors serve as the fundamental building blocks, while secondary colors add depth and dimension to your palette.`}</p>
    <h3>{`3. Define tertiary and accent colors`}</h3>
    <p>{`Dive into tertiary and accent colors to enrich your palette. Tertiary colors introduce subtle variations, enhancing the depth and complexity of your design. Accent colors, on the other hand, highlight key elements, drawing attention to essential aspects of your product design.`}</p>
    <h3>{`4. Generate and define color shade`}</h3>
    <p>{`Explore the creation and definition of color shades to expand your palette. Generating various shades and providing clear definitions is essential for developing a dynamic and versatile color palette. Follow the outlined techniques to effectively generate and define color shades for your designs.`}</p>
    <h3>{`5. Update your color system`}</h3>
    <p>{`Explore the creation and definition of color shades to expand your palette. Generating various shades and providing clear definitions is essential for developing a dynamic and versatile color palette. Follow the outlined techniques to effectively generate and define color shades for your designs.`}</p>
    <InlineNotification mdxType="InlineNotification">
      <p>{`Create a New Library: Create and publish your tribe or squad in the Following Steps using Legion UI Library - Internal. Then, contact the core team to assess your foundation before registering to Legion multi-theme to develop and create a website platform.`}</p>
    </InlineNotification>
    <h2>{`Define right typography`}</h2>
    <p>{`This section meticulously defines the appropriate font families and types to ensure a cohesive visual identity across all brand touchpoint. By selecting the right typography, including font families and types, we empower your brand to communicate effectively and leave a lasting impression on your audience.`}</p>
    <div {...{
      "className": "gatsby-resp-iframe-wrapper",
      "style": {
        "paddingBottom": "56.25%",
        "position": "relative",
        "height": "0",
        "overflow": "hidden"
      }
    }}>{` `}<iframe parentName="div" {...{
        "src": "https://www.youtube.com/embed/9vvkxaa4ZPU?si=dIf_vzu47Bngnd9D",
        "title": "YouTube video player",
        "frameBorder": "0",
        "allow": "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
        "referrerPolicy": "strict-origin-when-cross-origin",
        "allowFullScreen": true,
        "style": {
          "position": "absolute",
          "top": "0",
          "left": "0",
          "width": "100%",
          "height": "100%"
        }
      }}></iframe>{` `}</div>
    <h3>{`1. Select Font Family`}</h3>
    <p>{`Begin by exploring various font families that align with your brand’s personality and message, considering factors such as readability, style, and consistency across different platforms. Narrow down your choices to a few font families that best represent your brand identity, and evaluate how each one complements your logo and overall visual aesthetics.`}</p>
    <h3>{`2. Combine and test typography`}</h3>
    <p>{`Combine different fonts from your selected font families to create typography pairings, experimenting with various combinations of headline, sub headline, and body text fonts. Test these combinations across different mediums, such as websites, print materials, and mobile devices, and assess how well each combination conveys your brand.`}</p>
    <h3>{`3. Generate and update typography`}</h3>
    <p>{`Create style guidelines for each font, specifying font sizes, line spacing, and text formatting like bold and italic. Ensure consistent typography across all brand touchpoints for a cohesive visual identity, and regularly refine and update typography styles.`}</p>
    <InlineNotification mdxType="InlineNotification">
      <p>{`Create a New Library: Create and publish your tribe or squad in the Following Steps using Legion UI Library - Internal. Then, contact the core team to assess your foundation before registering to Legion multi-theme to develop and create a website platform.`}</p>
    </InlineNotification>
    <h2>{`Define Icon System and Illustration`}</h2>
    <p>{`In this section we establish a unified icon system and illustration style that seamlessly aligns with your brand identity. By defining icons and illustrations that reflect your brand’s personality and values, we ensure consistency and impactful visual communication across all channels.`}</p>
    <div {...{
      "className": "gatsby-resp-iframe-wrapper",
      "style": {
        "paddingBottom": "56.25%",
        "position": "relative",
        "height": "0",
        "overflow": "hidden"
      }
    }}>{` `}<iframe parentName="div" {...{
        "src": "https://www.youtube.com/embed/OWcdZfejsuE?si=xMjKJQ5yIvNhL-l9",
        "title": "YouTube video player",
        "frameBorder": "0",
        "allow": "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
        "referrerPolicy": "strict-origin-when-cross-origin",
        "allowFullScreen": true,
        "style": {
          "position": "absolute",
          "top": "0",
          "left": "0",
          "width": "100%",
          "height": "100%"
        }
      }}></iframe>{` `}</div>
    <h3>{`1. Match your users and concept`}</h3>
    <p>{`Identify the characteristics, preferences, and needs of your target users, aligning them with the values and personality of your brand. Ensure that your brand resonates with your audience, fostering a strong connection and understanding.`}</p>
    <h3>{`2. Find your icon system`}</h3>
    <p>{`Explore different icon styles and designs that suit your brand identity and effectively convey your message. Consider factors such as simplicity, clarity, and relevance to your product or service. Choose an icon system that aligns with your brand’s aesthetics and enhances user experience.`}</p>
    <h3>{`3. Setup match icon system`}</h3>
    <p>{`Once you’ve chosen your icon system, set clear guidelines for using it consistently across all brand touchpoint. Specify details like icon size, color, and alignment to keep visuals cohesive. Use the icon system across both digital and print materials for a unified brand experience.`}</p>
    <h3>{`4. Setup match illustration`}</h3>
    <p>{`Develop a consistent illustration style that matches your brand’s look and reinforces its message. Choose colors, tone, and detail that fit your brand’s personality. Make straightforward guidelines for creating and using illustrations to maintain consistency and impact in your communication.`}</p>
    <InlineNotification mdxType="InlineNotification">
      <p>{`Create a New Library: Create and publish your tribe or squad in the Following Steps using Legion UI Library - Internal. Then, contact the core team to assess your foundation before registering to Legion multi-theme to develop and create a website platform.`}</p>
    </InlineNotification>
    <h2>{`Finish setup foundation`}</h2>
    <p>{`This section finalizes the establishment of foundational elements, by ensuring all components are meticulously configured, your brand’s design system achieves cohesion and consistency across all aspects.`}</p>
    <div {...{
      "className": "gatsby-resp-iframe-wrapper",
      "style": {
        "paddingBottom": "56.25%",
        "position": "relative",
        "height": "0",
        "overflow": "hidden"
      }
    }}>{` `}<iframe parentName="div" {...{
        "src": "https://www.youtube.com/embed/0MyRHisTURs?si=0gVb62-NyY7-CbJN",
        "title": "YouTube video player",
        "frameBorder": "0",
        "allow": "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
        "referrerPolicy": "strict-origin-when-cross-origin",
        "allowFullScreen": true,
        "style": {
          "position": "absolute",
          "top": "0",
          "left": "0",
          "width": "100%",
          "height": "100%"
        }
      }}></iframe>{` `}</div>
    <h3>{`Create your own thumbnail`}</h3>
    <p>{`Design a customized thumbnail that effectively represents your content and captures viewers’ attention. Consider using compelling imagery, clear text, and vibrant colors to make your thumbnail stand out and entice clicks.`}</p>
    <h3>{`Checking and publish`}</h3>
    <p>{`Check your content for accuracy, clarity, and consistency. Look for spelling and grammar mistakes, make sure all links work, and confirm that the thumbnail matches your content. When everything’s good to go, publish your content across platforms for your audience.`}</p>
    <h2>{`Recommended Figma Plugins`}</h2>
    <p>{`Some recommended plugins to simplify your workflow, task, accessibility checking, and design quality:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.figma.com/community/plugin/1103648664059257410/supa-palette"
        }}><strong parentName="a">{`Supa Palette`}</strong></a>{`: Palette editor/generator for quickly beautiful, harmonious, and accessible palettes in a short time.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.figma.com/community/plugin/818203235789864127/batch-styler"
        }}><strong parentName="a">{`Batch Styler`}</strong></a>{`: Change values of multiple (text and color) styles at once but don’t want to go through the process of changing each text style.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.figma.com/community/plugin/931280467863251825/adee-comprehensive-accessibility-tool"
        }}><strong parentName="a">{`Adee Comprehensive Accessibility Tool`}</strong></a>{`: Test color contrast, simulate 8 color blind simulations, and test touch target sizes to ensure they meet the various device standards.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.figma.com/community/plugin/732603254453395948/stark-contrast-accessibility-tools"
        }}><strong parentName="a">{`Stark - Contrast & Accessibility Tools`}</strong></a>{`: Contrast Checker, Focus Order, Alt-Text Annotations, Vision Simulator, and more, all in one place.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.figma.com/community/plugin/798826066406007173/ditto-manage-copy-from-design-to-production"
        }}><strong parentName="a">{`Ditto`}</strong></a>{`: Single source of truth for everyone touching copy, from writers to designers to engineers.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      